<template>
  <div id="center">
    <div class="up">
      <div>
        <dv-border-box-12 id="deviceRun" style="padding: 10px">
          <div style="padding: 12px;font-size: 18px;color: #fff;font-weight: 500">终端运行情况</div>
          <ul style="display: flex;justify-content: space-around;padding-top: 20px">
            <li style="text-align: center">
              <img class="logo" src="../../assets/img/newBigScreen/icon运行次数@2x.png"/>
              <p class="logoText">运行次数(次)</p>
              <p class="number">{{formatter(totalRunningCount)}}</p>
            </li>
            <li style="text-align: center">
              <img class="logo" src="../../assets/img/newBigScreen/icon运行时间@2x.png"/>

              <p class="logoText">运行时间（min）</p>
              <p class="number">{{formatter(totalRunningTime)}}</p>
            </li>
            <li style="text-align: center">
              <img class="logo" src="../../assets/img/newBigScreen/运行距离@2x.png"/>
              <p class="logoText">运行距离（km）</p>

              <p class="number">{{formatter(totalRunningDistance)}}</p>
            </li>
            <li style="text-align: center">
              <img class="logo" src="../../assets/img/newBigScreen/icon开关门次数@2x.png"/>
              <p class="logoText">开关门次数（次）</p>

              <p class="number">{{formatter(totalDoorOpeningCount)}}</p>
            </li>
          </ul>
        </dv-border-box-12>
        <dv-border-box-12 id="terbox" style="padding: 10px">
          <div style="padding: 12px;font-size: 18px;color: #fff;font-weight: 500">设备维护统计</div>
          <ul style="display: flex;justify-content: space-around;">
            <li class="terminalList">
              <div>
                <p class="terminalTitle">保养台量</p>
                <p class="terminalNumber">{{maintainQuantity}}</p>
                <p class="terminalTitle">已完成台量</p>
                <p class="terminalNumber">{{maintainCompleteQuantity}}</p>
              </div>
              <div>
                <div id="terminalPic1" style="width: 100px;height: 100px;margin-top: 10px"></div>
                <p style="color: white;text-align: center;margin-bottom: 10px;height: 30px;line-height: 30px">保养完成率</p>
              </div>
            </li>
            <li class="terminalList">
              <div>
                <p class="terminalTitle">维修台量</p>
                <p class="terminalNumber2">{{repairQuantity}}</p>
                <p class="terminalTitle">已完成台量</p>
                <p class="terminalNumber2">{{repairCompleteQuantity}}</p>
              </div>
              <div>
                <div id="terminalPic2" style="width: 100px;height: 100px;margin-top: 10px"></div>
                <p style="color: white;text-align: center;margin-bottom: 10px;height: 30px;line-height: 30px">维修完成率</p>
              </div>
            </li>
            <li class="terminalList">
              <div>
                <p class="terminalTitle">检测台量</p>
                <p class="terminalNumber3">{{testQuantity}}</p>
                <p class="terminalTitle">已完成台量</p>
                <p class="terminalNumber3">{{testCompleteQuantity}}</p>
              </div>
              <div>
                <div id="terminalPic3" style="width: 100px;height: 100px;margin-top: 10px"></div>
                <p style="color: white;text-align: center;margin-bottom: 10px;height: 30px;line-height: 30px">检测完成率</p>
              </div>
            </li>
          </ul>
        </dv-border-box-12>
      </div>
    </div>
  </div>
</template>

<script>
  import * as echarts from "echarts";
  import tdTheme from "@/common/echart/theme.json";
  import loginUtil from "@/util/loginUtil";
  export default {

    data() {
      return {
        titleItem:[],
        config: { value: 66,
                  localGradient: true},
        totalRunningCount:1,
        totalRunningTime:1,
        totalRunningDistance:1,
        totalDoorOpeningCount:1,
        water: {
          data: [24, 45],
          shape: "roundRect",
          formatter: "{value}%",
          waveNum: 3,
        },
        // 通过率和达标率的组件复用数据
        rate: [
          {
            id: "centerRate1",
            tips: 60,
            colorData: {
              textStyle: "#3fc0fb",
              series: {
                color: ["#00bcd44a", "transparent"],
                dataColor: {
                  normal: "#03a9f4",
                  shadowColor: "#97e2f5",
                },
              },
            },
          },
          {
            id: "centerRate2",
            tips: 40,
            colorData: {
              textStyle: "#67e0e3",
              series: {
                color: ["#faf3a378", "transparent"],
                dataColor: {
                  normal: "#ff9800",
                  shadowColor: "#fcebad",
                },
              },
            },
          },
        ],
        data:{},
        repairQuantity:1,
        repairCompleteQuantity:1,
        maintainQuantity:1,
        maintainCompleteQuantity:1,
        testQuantity:1,
        testCompleteQuantity:1,
      };
    },
    computed: {

    },
    mounted() {
      this.getData();
      this.getTermialData();
      this.timer=window.setInterval(()=>{
        this.getTermialData();
      },10000);
    },
    beforeDestroy() {
      window.clearInterval(this.timer);
      this.timer=null;
    },
    methods:{
      getData() {
        let http =   this.$http.get("/big-screen/device-operation");
        http.then(data => {
          this.totalRunningCount=data.totalRunningCount;
          this.totalRunningTime=data.totalRunningTime;
          this.totalRunningDistance=data.totalRunningDistance;
          this.totalDoorOpeningCount=data.totalDoorOpeningCount;

        }).catch(() => {
          // this.status = "error";
        });
      },
      getTermialData() {
        let http =   this.$http.get("/big-screen/repair-maintain-check/completeRate",{maintenanceType:"as_need",companyId: loginUtil.getCompanyCode()});
        http.then(data => {
          this.drawComplete1Pic(data.maintainCompleteRate,1);
          this.drawComplete1Pic(data.repairCompleteRate,2);
          this.drawComplete1Pic(data.testCompleteRate,3);
          this.repairQuantity=data.repairQuantity;
          this.repairCompleteQuantity=data.repairCompleteQuantity;
          this.maintainQuantity=data.maintainQuantity;
          this.maintainCompleteQuantity=data.maintainCompleteQuantity;
          this.testQuantity=data.testQuantity;
          this.testCompleteQuantity=data.testCompleteQuantity;

        }).catch(() => {
          // this.status = "error";
        });
      },
      drawComplete1Pic(data,num) {
        const chart = echarts.init(document.querySelector("#terminalPic"+num),tdTheme);
        const gaugeData = [
          {
            value:data,
            // name: "Perfect",
            // title: {
            //   offsetCenter: ["0%", "-30%"],
            // },
            // detail: {
            //   valueAnimation: true,
            //   offsetCenter: ["0%", "-20%"],
            // },
          },

        ];
        let color ="#78FF91";
        switch (num) {
          case 1:
            color="#E8BA1F";
            break;
          case 2:
            color="#3DE8FC";
            break;
          default:
            color="#78FF91";
        }
        const option = {
          name:{
            title:"保养完成率",
          },
          series: [
            {
              type: "gauge",
              startAngle: 90,
              endAngle: -270,
              pointer: {
                show: false,
              },
              progress: {
                show: true,
                overlap: false,
                roundCap: true,
                clip: false,
                itemStyle: {
                  borderWidth: 1,
                  color,
                },
              },
              splitLine: {
                show: false,
                distance: 0,
                length: 10,
              },
              axisTick: {
                show: false,
              },
              axisLine: {
                lineStyle: {
                  width: 5,
                },
              },
              axisLabel: {
                show: false,
                distance: 50,
              },
              data: gaugeData,
              title: {
                fontSize: 14,
              },
              detail:{
                color:"white",
                textStyle:{
                  fontSize:"18px",
                },
                formatter:function(parmas) {
                  return parmas+"%";
                },
              },
            },
          ],
        };
        chart.setOption(option);
      },
      formatter(number) {
        const str = number+"";
        const numbers = str.split("").reverse();
        const segs = [];

        while (numbers.length) segs.push(numbers.splice(0, 3).join(""));

        return segs.join(",").split("").reverse().join("");
      },
    },
  };
</script>

<style lang="scss" scoped>
#center {
  display: flex;
  flex-direction: column;
  .up {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    #deviceRun{
      height: 220px;
      width: 710px;
    }
    #terbox{
      width: 710px;
      height: 220px;
    }
    .logo{
      width: 82px;height: 48px
    }
    .logoText{

      height: 14px;
      font-size: 14px;
      font-family: Source Han Sans CN, Source Han Sans CN-Medium;
      font-weight: 500;
      text-align: center;
      color: #ffffff;
      line-height: 36px;
    }
    .number{
      margin-top: 10px;
      text-align: left;
      width: 100px;
      height: 25px;
      font-size: 20px;
      font-family: Roboto, Roboto-Bold;
      font-weight: 700;
      color: #00d5f5;
      line-height: 36px;
    }
    .terminalList{
      width: 185px;
      display: flex;
      height: 130px;
      margin: 0 2px;
      //opacity: 0.22;
      //background: #113570;
      background-color: rgba(17, 53, 112,0.22);
    }
    .terminalNumber{
      margin: 15px 0;
      width: 57px;
      height: 18px;
      font-size: 20px;
      font-weight: 400;
      text-align: center;
      color: #e8ba1f;
      line-height: 36px;
    }
    .terminalNumber2{
      margin: 15px 0;
      width: 57px;
      height: 18px;
      font-size: 20px;
      font-weight: 400;
      text-align: center;
      color: #3DE8FC;
      line-height: 36px;
    }
    .terminalNumber3{
      margin: 15px 0;
      width: 57px;
      height: 18px;
      font-size: 20px;
      font-weight: 400;
      text-align: center;
      color: #78FF91;
      line-height: 36px;
    }
    .terminalTitle{
      height: 14px;
      font-size: 14px;
      opacity: 1;
      font-weight: 400;
      text-align: center;
      color: #fff;
      line-height: 36px;
    }
    #terminalPic1{
      width: 75px;
      height: 75px;
    }

  }
}
</style>
